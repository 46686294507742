@media (max-width: 767px) {
    /* On small screens, the nav menu spans the full width of the screen. Leave a space for it. */
    body {
        padding-top: 50px;
    }
}

#root {
    background: linear-gradient(45deg, #4493f038, #1A50E5);
}

:root {
    --top-menu-height: 4em;
}

.top-menu {
    height: var(--top-menu-height);
}

.layout-grid {
    width: 100%;
    display: grid;
    grid: var(--top-menu-height) / 1fr;
    grid-template-areas: "menu" "maincontent";
}

.row {
    margin-left: 0px
}

.menu {
    grid-area: menu;
    z-index: 100;
}

.main-content {
    grid-area: maincontent;
    background-color: #f9fafb;
}

.parent {
    display: inline-flex;
    width: 100%
}

.side {
    transition: width 0.3s;
    width: 20em !important;
    overflow: hidden;
    padding-top: var(--top-menu-height);
    z-index: 99;
}

.small-side {
    width: 4em !important;
    flex-basis: 4em;
}

.normal-content {
    right: 0;
    transition: all 0.3s;
    padding: 10px;
    width: calc(100% - 20em);
    background-color: #f5f5f5
}

.small-content {
    padding: 10px;
    width: calc(100% - 4em);
}

.logo-space-menu-item {
    width: 20em;
}

.display-inline {
    display: inline-flex;
    align-items: center;
}

.logo-space {
    font-family: "Roboto Light", serif;
    font-size: 24px;
    margin: 0 auto;

}

.logo-space img, .display-inline img {
    margin-right: 8px;
}

.no-border::before {
    display: none;
}

.top-menu i.icon {
    margin: 0 !important;
}

.drop-left-padding {
    padding-left: 0 !important;
}

.label-on-corner {
    top: 0.8em !important;
    left: 78% !important;
}

.ui.form.editing input[type=text],
.ui.form.editing input[type=number],
.ui.form.editing .ui.selection.dropdown {
    border: 1px solid rgba(0, 181, 173, 0.72) !important
}

.ui.form.editing .field > label {
    font-weight: 700
}

.ui.form.readOnly .field > label {
    font-weight: 500
}

.ui.form.editing > .ui.toggle.checkbox input:checked ~ .box:before,
.ui.form.editing > .ui.toggle.checkbox input:checked ~ label:before {
    background-color: #00b5ad !important
}

.ui.form.editing > .ui.toggle.checkbox input ~ .box:before,
.ui.form.editing > .ui.toggle.checkbox input ~ label:before {
    background-color: #db2828 !important
}

.ui.form.editing > .ui.grid > div.column > .ui.toggle.checkbox input:checked ~ .box:before,
.ui.form.editing > .ui.grid > div.column > .ui.toggle.checkbox input:checked ~ label:before {
    background-color: #00b5ad !important
}

.ui.form.editing > .ui.grid > div.column > .ui.toggle.checkbox input ~ .box:before,
.ui.form.editing > .ui.grid > div.column > .ui.toggle.checkbox input ~ label:before {
    background-color: #db2828 !important
}

.ui.form.editing > .ui.grid > div.column > .ui.toggle.checkbox input {
    left: 22px
}

.ui.form.editing > .ui.grid > div.column > .ui.toggle.checkbox {
    margin-top: 3px
}

.ui.selection.dropdown .menu {
    max-height: 10rem !important;
}

.panel-title {
    font-size: 14px !important
}

.ui.card > .button:last-child, .ui.cards > .card > .button:last-child {
    border-radius: .28571429rem !important;
    width: 31px;
    position: absolute;
    right: 5px;
    bottom: 5px;
    background: rgba(255, 255, 255, 0.58);
    color: red;
    border: 1px solid rgba(0, 181, 173, 0.72);
}

.ui.toggle.checkbox {
    margin-top: 10px !important;
}

.ui.styled.accordion .accordion .content {
    padding: 0em 1em 1em !important;
}

.ui.styled.accordion .accordion .content, .ui.styled.accordion .content {
    padding: .5em 1em 1em !important;
}

.timeline div h4 {
    width: 150px;
    text-align: right;
    position: absolute;
    left: -200px;
    top: 5px;
    color: black
}

.timeline .loader {
    margin-left: 180px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}

.ui.table td.accordion-report.active td, .ui.table tr.accordion-report.active td {
    background: #f3937ecc !important
}

.ui.table.accordion-report {
    background: #f3937e77 !important
}

.ui.table.legend {
    border: 1px solid rgba(34,36,38,1)
}

tr.active.title.accordion-report ~ tr.title.accordion-report>td  {
    border-top: 1px solid black;
}

tr.active.title.accordion-report td{
    border-top: 1px solid black;
}


.ui.positive.message {
    background-color: #d4e0b9 !important;
}

.ui.vertical.menu .item .menu .active.item {
    background: rgba(0,0,0,.05) !important;
    border-radius: 0;
    box-shadow: none;
}

div.logo.logo-space-menu-item {
    padding: 20px !important;
}

.date-time :not(td){
    width: calc(25vw - 110px) !important
}

div.field:has(.date-time-picker) {
    width: 100% !important
}
.date-time-picker div {
    width: 100% !important
}
.ui.table.cama tbody tr td:not(:first-child) {
    text-align: center;
    vertical-align: middle;
}
.ui.striped.table tbody tr:nth-child(2n), .ui.striped.table>tr:nth-child(2n) {
    background-color: rgb(191 191 191 / 100%) !important;
}

.ui.striped.table tbody tr:nth-child(odd), .ui.striped.table>tr:nth-child(odd){
    background-color: rgb(208 212 221 / 100%) !important;
}
.ui.table.cama th:not(:first-child)  {
    background-color: rgb(191 191 191 / 100%) !important;
    text-align: center;
    vertical-align: middle;
}
.ui.table.cama td, .ui.table.cama th  {
    border-right-width: 4px !important;
    border-left-width: 2px !important;
    border-top-width: 2px !important;
    border-color: white !important;
}

.ui.large.progress {
    height: 2.5em !important
}
.ui.inverted.progress .bar>.progress {
    color: white !important;
    background: transparent !important;
}
.blurring.dimmable>.dimmer {
    background-color: rgb(0 0 0 / 95%) !important;
}

input[type=range]{
    -webkit-appearance: none;
}

input[type=range]::-webkit-slider-runnable-track {
    width: 300px;
    height: 5px;
    background: #00b5ad;
    border: none;
    border-radius: 3px;
}

input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #00b5ad;
    margin-top: -4px;
}

input[type=range]:focus {
    outline: none;
}

input[type=range]:focus::-webkit-slider-runnable-track {
    background: #00b5ad;
}

.blurring.dimmed.dimmable>:not(.dimmer), .blurring.dimmed.dimmable>:not(.popup){
    filter: none !important;
    -webkit-filter: none !important;
}
